<template>
  <div class="tableView" v-if="searchStatus == 1">
    <div class="tile">
      <b-table
        :data="teas"
        paginated
        :current-page.sync="currentPage"
        ref="table"
        per-page="10"
        default-sort="name"
        detailed
        detail-key="id"
        @details-open="
          (row, index) => $buefy.toast.open(`Expanded ${row.name}`)
        "
      >
        <template slot-scope="props">
          <b-table-column field="img" width="150">
            <!--
            <b-image
              :src="props.row.images[0].url + '&height=128'"
              alt="l"
              ratio="601by235"
              rounded="True"
            >
            </b-image>
            -->
            <div v-if="props.row.images">
              <figure class="media-left">
                <p class="image is-128x128">
                  <a :href="props.row.url" target="_blank">
                    <img
                      style="width: auto !important"
                      :src="props.row.images[0].url + '&height=128'"
                    />
                  </a>
                </p>
              </figure>
            </div>
          </b-table-column>

          <b-table-column field="name" label="Name" width="250" sortable>
            {{ props.row.name }}
            <br />
            <a @click="toggle(props.row)">read more...</a>
          </b-table-column>

          <b-table-column field="year" label="Year" width="80" sortable>
            {{ props.row.year }}
          </b-table-column>

          <b-table-column field="shop" label="Shop" width="145" sortable>
            <a :href="props.row.url" target="_blank">{{ props.row.shop }}</a>
          </b-table-column>

          <b-table-column field="brand" label="Brand" width="150" sortable>
            {{ props.row.brand }}
          </b-table-column>

          <!-- // Feature?
          <b-table-column field="save" label="Save"
            ><b-icon pack="far" icon="save"></b-icon>
          </b-table-column>
          -->

          <b-table-column
            field="avgPrice"
            label="$/gr"
            width="100"
            sortable
            :custom-sort="sortAvgPrice"
          >
            <span v-if="props.row.sold_out">
              <span style="text-decoration: line-through">
                ${{ props.row.avg_price_per_gram }}</span
              ><br />
              sold out
            </span>
            <span v-else> ${{ props.row.avg_price_per_gram }} </span>
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <article class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  {{ props.row.description }}
                </p>
              </div>
            </div>
            <div class="media-right">
              <b-table :data="props.row.units">
                <template slot-scope="props">
                  <b-table-column field="title" label="Title">
                    <span v-if="props.row.available">
                      {{ props.row.title }}
                    </span>
                    <span v-else style="text-decoration: line-through">
                      {{ props.row.title }}
                    </span>
                  </b-table-column>
                  <b-table-column field="weight" label="Weight">
                    <span v-if="props.row.available">
                      {{ props.row.weight }}gr
                    </span>
                    <span v-else style="text-decoration: line-through">
                      {{ props.row.weight }}gr
                    </span>
                  </b-table-column>
                  <b-table-column field="price" label="Price">
                    <span v-if="props.row.available">
                      ${{ props.row.price }}
                    </span>
                    <span v-else style="text-decoration: line-through">
                      ${{ props.row.price }}
                    </span>
                  </b-table-column>
                  <b-table-column field="price" label="$ / gr">
                    <span v-if="props.row.available">
                      ${{ props.row.price_per_gram }}
                    </span>
                    <span v-else style="text-decoration: line-through">
                      ${{ props.row.price_per_gram }}
                    </span>
                  </b-table-column>
                </template>
              </b-table>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
  <div v-else-if="searchStatus == -1">Sorry we did not find anything.</div>
  <div v-else></div>
</template>

<script>
export default {
  name: "TeaTable",
  data() {
    return { perPage: 10, currentPage: 1 };
  },
  computed: {
    teas() {
      return this.$store.state.teas;
    },
    searchStatus() {
      return this.$store.state.searchStatus;
    }
  },
  filters: {
    round(n) {
      if (n) {
        return n.toFixed(3);
      } else {
        return "";
      }
    }
  },
  methods: {
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    sortAvgPrice(a, b, isAsc) {
      return isAsc
        ? a.avg_price_per_gram > b.avg_price_per_gram
        : a.avg_price_per_gram < b.avg_price_per_gram;
    }
  },
  watch: {
    teas() {
      this.currentPage = 1;
    },
    currentPage() {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 500);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table td {
  vertical-align: middle !important;
}
</style>
