<template>
  <section class="section home has-text-centered">
    <img src="front_2.png" alt="tea" />
    <h1 class="title">tea-scout.com</h1>
    <h2 class="subtitle">
      is a tea page for finding teas from tea shops we love.
    </h2>
    <p>
      You can search for your favorite tea or browse through the wide variety of
      tea offers.
    </p>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  mounted() {
    this.$nextTick(function() {});
  }
};
</script>
