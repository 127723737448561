const axios = require("axios");
const api_location = "https://tea-scout.com/api/";
// const api_location = "http://localhost:9000";

const instance = axios.create({
  withCredentials: true,
  baseURL: api_location,
});

export default {
  name: "TeaScoutApi",
  search(category, word) {
    let method = "query";
    let api_call = `${method}/${category}/${word}`;
    return this.call(api_call);
  },
  browse(categoryId) {
    let method = "browse";
    let api_call = `${method}/${categoryId}`;
    return this.call(api_call);
  },
  autocomplete(category, word) {
    let method = "suggest";
    let api_call = `${method}/${category}/${word}`;
    return this.call(api_call);
  },
  categories() {
    let api_call = `categories`;
    return this.call(api_call);
  },
  filterResult(payload) {
    let api_call = `filter`;
    return instance
      .post(api_call, JSON.stringify(payload))
      .then(function (resp) {
        return resp.data;
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  },
  teaShopData() {
    let api_call = "shops/teacount";
    return this.call(api_call);
  },
  teasPerCategory() {
    let api_call = "categories/teacount";
    return this.call(api_call);
  },
  shopPriceYearType(categoryId) {
    let method = "/shops/shop_price_year_type";
    let api_call = `${method}/${categoryId}`;
    return this.call(api_call);
  },
  allTeas() {
    let api_call = "all";
    return this.call(api_call);
  },
  getStatText() {
    let api_call = "/stats";
    return this.call(api_call);
  },
  call(api_call) {
    return instance.get(api_call).then(function (resp) {
      return resp.data;
    });
  },
};
