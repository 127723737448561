import Vue from "vue";
import Vuex from "vuex";
import TeaScoutApi from "@/api/TeaScoutApi.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    teas: [],
    searchStatus: 0,
    shops: [],

    searchTerm: "",
    lastSearchTerm: null,
    lastSearchCategory: null,

    categories: [],
    // selectedCategory: { id: 0, title: "all", displayTitle: "All" },
    selectedCategory: { id: 0, title: "all", display_title: "All" },
    // price
    priceRange: null,
    selectedPriceRange: [],
    // avg price
    averagePriceRange: null,
    selectedAveragePriceRange: [],
    // time
    timeRange: null,
    selectedTimeRange: [],
    // weight
    weightRange: null,
    selectedWeightRange: []
  },
  mutations: {
    resetState: state => {
      state.teas = [];
      state.searchStatus = 0;
      state.shops = [];

      state.searchTerm = "";

      state.categories = [];
      state.selectedCategory = { id: 0, title: "all", display_title: "All" };
      // price
      state.priceRange = null;
      state.selectedPriceRange = [];
      // avg price
      state.averagePriceRange = null;
      state.selectedAveragePriceRange = [];
      // time
      state.timeRange = null;
      state.selectedTimeRange = [];
      // weight
      state.weightRange = null;
      state.selectedWeightRange = [];
    },
    setSearchTerm: (state, payload) => {
      state.searchTerm = payload;
    },
    setLastSearchTerm: (state, payload) => {
      state.lastSearchTerm = payload;
    },
    setLastSearchCategory: (state, payload) => {
      state.lastSearchCategory = payload;
    },
    setSelectedCategory: (state, payload) => {
      state.selectedCategory = payload;
    },
    setSearchResult: (state, payload) => {
      state.teas = [];

      if (payload != null) {
        state.searchStatus = 1;
      } else {
        state.searchStatus = -1;
      }
      state.teas = payload;
    },
    setCategories: (state, payload) => {
      state.categories = payload.categories;
    },
    setShops: state => {
      let all_shops = new Set();
      state.teas.forEach(tea =>
        all_shops.add(
          JSON.stringify({ id: tea.shop_id, name: tea.shop, display: true })
        )
      );
      state.shops = state.shops = Array.from(all_shops).map(jsonStr => {
        let shopToBeAssigned = JSON.parse(jsonStr);
        return shopToBeAssigned;
      });
    },
    setPriceRange: (state, data) => {
      state.priceRange = data;
    },
    setSelectedPriceRange: (state, data) => {
      state.selectedPriceRange = [data.min, data.max];
    },
    setAveragePriceRange: (state, data) => {
      state.averagePriceRange = data;
    },
    setSelectedAveragePriceRange: (state, data) => {
      state.selectedAveragePriceRange = [data.min, data.max];
    },
    setWeightRange: (state, data) => {
      state.weightRange = data;
    },
    setSelectedWeightRange: (state, data) => {
      state.selectedWeightRange = [data.min, data.max];
    },
    setTimeRange: (state, data) => {
      state.timeRange = data;
    },
    setSelectedTimeRange: (state, data) => {
      state.selectedTimeRange = [data.min, data.max];
    },
    updateShops(state, value) {
      state.shops = value;
    },
    updateSelectedPriceRange(state, value) {
      state.selectedPriceRange = value;
    },
    updateSelectedAveragePriceRange(state, value) {
      state.selectedAveragePriceRange = value;
    },
    updateSelectedWeightRange(state, value) {
      state.selectedWeightRange = value;
    },
    updateSelectedTimeRange(state, value) {
      state.selectedTimeRange = value;
    }
  },
  getters: {
    categories: state => {
      return state.categories;
    },
    tags: state => {
      if (state.teas.length === 0) {
        return false;
      } else {
        let all_tags = [];
        state.teas.forEach(tea => {
          if (tea.tags != null) {
            all_tags.push(...tea.tags);
          }
        });
        return all_tags.filter((tag, index, self) => {
          return index === self.findIndex(t => t.name === tag.name);
        });
      }
    },
    timeRange: state => {
      return state.timeRange;
    },
    shops: state => {
      return state.shops;
    },
    priceRange: state => {
      return state.priceRange;
    },
    averagePriceRange: state => {
      return state.averagePriceRange;
    },
    weightRange: state => {
      return state.weightRange;
    },
    selectedPriceRange: state => {
      return state.selectedPriceRange;
    },
    selectedAveragePriceRange: state => {
      return state.selectedAveragePriceRange;
    },
    selectedWeightRange: state => {
      return state.selectedWeightRange;
    },
    selectedTimeRange: state => {
      return state.selectedTimeRange;
    },
    selectedCategory: state => {
      return state.selectedCategory;
    },
    searchTerm: state => {
      return state.searchTerm;
    }
  },
  actions: {
    newSearch(context, payload) {
      context.dispatch("pushResult", payload.data);
      context.commit("setLastSearchTerm", context.state.searchTerm);
      context.commit(
        "setLastSearchCategory",
        context.state.selectedCategory.title
      );
    },
    pushResult(context, result) {
      context.commit("setSearchResult", result);
      if (context.state.searchStatus == 1) {
        context.commit("setShops");

        context.dispatch("pushPriceRange", true);
        context.dispatch("pushAveragePriceRange", true);
        context.dispatch("pushWeightRange", true);
        context.dispatch("pushTimeRange", true);
      }
    },
    newFilterResult(context, payload) {
      context.commit("setSearchResult", payload);
      // context.commit("setShops");

      context.dispatch("pushPriceRange", false);
      context.dispatch("pushAveragePriceRange", false);
      context.dispatch("pushWeightRange", false);
      context.dispatch("pushTimeRange", false);
    },
    getCategories(context) {
      TeaScoutApi.categories().then(data =>
        context.commit("setCategories", data)
      );
    },
    pushPriceRange(context, search) {
      let all_units = [];
      context.state.teas.forEach(tea => {
        all_units.push(...tea.units);
      });
      const priceRange = {
        min: Math.min(...all_units.map(unit => unit.price)),
        max: Math.max(...all_units.map(unit => unit.price))
      };
      if (search) {
        context.commit("setPriceRange", priceRange);
        context.commit("setSelectedPriceRange", priceRange);
      } else {
        context.commit("setSelectedPriceRange", priceRange);
      }
    },
    pushAveragePriceRange(context, search) {
      let all_avg_prices = [];
      context.state.teas.forEach(tea =>
        all_avg_prices.push(tea.avg_price_per_gram)
      );
      const averagePriceRange = {
        min: Math.min(...all_avg_prices),
        max: Math.max(...all_avg_prices)
      };
      if (search) {
        context.commit("setAveragePriceRange", averagePriceRange);
        context.commit("setSelectedAveragePriceRange", averagePriceRange);
      } else {
        context.commit("setSelectedAveragePriceRange", averagePriceRange);
      }
    },
    pushWeightRange(context, search) {
      let all_units = [];
      context.state.teas.forEach(tea => all_units.push(...tea.units));
      const weightRange = {
        min: Math.min(...all_units.map(unit => unit.weight)),
        max: Math.max(...all_units.map(unit => unit.weight))
      };
      if (search) {
        context.commit("setWeightRange", weightRange);
        context.commit("setSelectedWeightRange", weightRange);
      } else {
        context.commit("setSelectedWeightRange", weightRange);
      }
    },
    pushTimeRange(context, search) {
      let all_years = [];
      context.state.teas.forEach(tea => {
        if (tea.year != null) {
          all_years.push(tea.year);
        }
      });
      const timeRange = {
        min: Math.min(...all_years),
        max: Math.max(...all_years)
      };
      if (search) {
        context.commit("setTimeRange", timeRange);
        context.commit("setSelectedTimeRange", timeRange);
      } else {
        context.commit("setSelectedTimeRange", timeRange);
      }
    }
  },
  modules: {}
});
