import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Search from "../views/Search.vue";
import Browse from "../views/Browse.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    beforeEnter: (to, from, next) => {
      if (from.name == "Browse") {
        store.commit("resetState");
      }
      next();
    }
  },
  {
    path: "/browse",
    name: "Browse",
    component: Browse,
    beforeEnter: (to, from, next) => {
      if (from.name == "Search") {
        store.commit("resetState");
      }
      next();
    }
  },
  /*{
    path: "/result",
    name: "Results",
    component: ResultView
  },*/
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/stats",
    name: "Stats",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Stats.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

export default router;
