<template>
  <b-field>
    <b-select
      placeholder="Please select a category"
      v-model="selectedCategory"
      required
    >
      <option v-for="option in categories" :value="option" :key="option.title">
        {{ option.display_title }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "CategorySelect",
  created() {
    this.$store.dispatch("getCategories");
  },
  props: {
    titleInValue: Boolean
  },
  data() {
    return {};
  },

  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    selectedCategory: {
      get() {
        return this.$store.getters.selectedCategory;
      },
      set(value) {
        this.$store.commit("setSelectedCategory", value);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  color: #000 !important;
}
</style>
