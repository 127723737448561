<template>
  <section class="searchBar">
    <div class="container">
      <!-- <form v-on:submit.prevent="runQuery" action="javascript:void(0);">-->
      <div class="columns">
        <div class="column">
          <CategorySelect v-bind:title-in-value="false"></CategorySelect>
        </div>
        <div class="column is-two-thirds"></div>
        <div class="column has-text-centered">
          <b-button
            type="is-primary"
            class="is-war"
            native-type="submit"
            v-on:click="runQuery()"
            icon-left="search"
          >
            browse
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TeaScoutApi from "@/api/TeaScoutApi.js";
import CategorySelect from "@/components/CategorySelect.vue";

export default {
  name: "Browse",
  components: {
    CategorySelect
  },
  data() {
    return {};
  },
  methods: {
    runQuery() {
      const loadingComponent = this.$buefy.loading.open();
      TeaScoutApi.browse(this.$store.state.selectedCategory.id).then(data => {
        this.$store.dispatch("pushResult", data.result);
        loadingComponent.close();
      });
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.runQuery();
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
