<template>
  <section class="dFilters">
    <div class="has-text-right" v-if="filterAction">
      <b-button
        size="is-small"
        type="is-danger"
        outlined
        icon-left="undo"
        @click.stop="resetFilter()"
      >
        reset
      </b-button>
    </div>
    <div class="priceFilter" v-if="priceRange">
      <b-field label="Price">
        <b-slider
          v-model="selectedPriceRange"
          :min="priceRange.min"
          :max="priceRange.max"
          :custom-formatter="val => '$' + val"
          @change="filterUnits()"
          :step="10"
        >
        </b-slider>
      </b-field>
      <div class="has-text-centered">
        ${{ selectedPriceRange[0] }} - ${{ selectedPriceRange[1] }}
      </div>
    </div>
    <div class="averagePriceFilter" v-if="averagePriceRange">
      <b-field label="$/gr">
        <b-slider
          v-model="selectedAveragePriceRange"
          :min="averagePriceRange.min"
          :max="averagePriceRange.max"
          :custom-formatter="val => '$' + round(val)"
          @change="filterUnits()"
          :step="0.05"
        >
        </b-slider>
      </b-field>
      <div class="has-text-centered">
        ${{ round(selectedAveragePriceRange[0]) }} - ${{
          round(selectedAveragePriceRange[1])
        }}
      </div>
    </div>
    <div class="weightFilter" v-if="weightRange">
      <b-field label="Weight">
        <b-slider
          v-model="selectedWeightRange"
          :min="weightRange.min"
          :max="weightRange.max"
          :custom-formatter="val => val + 'gr'"
          @change="filterUnits()"
          :step="10"
        >
        </b-slider>
      </b-field>
      <div class="has-text-centered">
        {{ selectedWeightRange[0] }}gr - {{ selectedWeightRange[1] }}gr
      </div>
    </div>
    <div class="yearFilter" v-if="timeRange">
      <b-field label="Year">
        <b-slider
          v-model="selectedTimeRange"
          :min="timeRange.min"
          :max="timeRange.max"
          :step="1"
          @change="filterUnits()"
        >
        </b-slider>
      </b-field>
      <div class="has-text-centered">
        {{ selectedTimeRange[0] }} -
        {{ selectedTimeRange[1] }}
      </div>
      <div class="label">Shops</div>
      <div v-for="shop in shops" :key="shop.id">
        <b-checkbox v-model="shop.display" @input="setShops()">
          {{ shop.name }}
        </b-checkbox>
      </div>

      <!--
      <div class="has-text-centered">
        <b-button
          size="is-medium"
          icon-left="filter"
          @click.stop="filterUnits()"
        >
          filter
        </b-button>
      </div>
      -->
    </div>
  </section>
</template>

<script>
import TeaScoutApi from "@/api/TeaScoutApi.js";

export default {
  name: "Filters",
  data() {
    return {
      filterAction: false
    };
  },
  methods: {
    filterTeaByUnits() {
      const loadingComponent = this.$buefy.loading.open();
      let payload = {
        shopIds: this.$store.state.shops
          .filter(shop => shop.display == true)
          .map(shop => {
            return shop.id;
          }),
        minPrice: this.$store.state.selectedPriceRange[0],
        maxPrice: this.$store.state.selectedPriceRange[1],
        minAvgPriceperGram: this.$store.state.selectedAveragePriceRange[0],
        maxAvgPriceperGram: this.$store.state.selectedAveragePriceRange[1],
        minWeight: this.$store.state.selectedWeightRange[0],
        maxWeight: this.$store.state.selectedWeightRange[1],
        minYear: this.$store.state.selectedTimeRange[0],
        maxYear: this.$store.state.selectedTimeRange[1]
      };
      TeaScoutApi.filterResult(payload).then(data => {
        this.$store.dispatch("newFilterResult", data.result);
        loadingComponent.close();
      });
    },
    filterUnits() {
      this.filterTeaByUnits();
      this.filterAction = true;
    },
    resetFilter() {
      const loadingComponent = this.$buefy.loading.open();
      TeaScoutApi.search(
        this.$store.state.lastSearchCategory,
        this.$store.state.lastSearchTerm
      ).then(data => {
        this.$store.dispatch("newSearch", { data: data.result });
        loadingComponent.close();
      });

      this.filterAction = false;
    },
    setShops() {
      this.$store.commit("updateShops", this.shops);
      this.filterTeaByUnits();
    },
    round(n) {
      if (n) {
        return n.toFixed(3);
      } else {
        return "";
      }
    }
  },
  computed: {
    priceRange() {
      return this.$store.getters.priceRange;
    },
    averagePriceRange() {
      return this.$store.getters.averagePriceRange;
    },
    weightRange() {
      return this.$store.getters.weightRange;
    },
    timeRange() {
      return this.$store.getters.timeRange;
    },
    shops() {
      return this.$store.getters.shops;
    },
    selectedPriceRange: {
      get() {
        return this.$store.getters.selectedPriceRange;
      },
      set(value) {
        this.$store.commit("updateSelectedPriceRange", value);
      }
    },
    selectedAveragePriceRange: {
      get() {
        return this.$store.getters.selectedAveragePriceRange;
      },
      set(value) {
        this.$store.commit("updateSelectedAveragePriceRange", value);
      }
    },
    selectedWeightRange: {
      get() {
        return this.$store.getters.selectedWeightRange;
      },
      set(value) {
        this.$store.commit("updateSelectedWeightRange", value);
      }
    },
    selectedTimeRange: {
      get() {
        return this.$store.getters.selectedTimeRange;
      },
      set(value) {
        this.$store.commit("updateSelectedTimeRange", value);
      }
    }
  }
};
</script>
<style>
.dFilters {
  padding: 2.5em 0;
}
</style>
