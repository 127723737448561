<template>
  <section class="searchBar">
    <div class="container">
      <!-- <form v-on:submit.prevent="runQuery" action="javascript:void(0);">-->
      <div class="columns">
        <div class="column">
          <CategorySelect title-in-value></CategorySelect>
        </div>
        <div class="column is-two-thirds">
          <b-field>
            <b-autocomplete
              rounded
              v-model="searchTerm"
              required
              :data="data"
              placeholder="and enter your search term e.g. ba xian"
              icon="search"
              clearable
              :loading="isFetching"
              @typing="getSuggestions"
              @select="option => (searchTerm = option)"
              v-on:keyup.enter.native="runQuery(searchTerm)"
            >
              <!-- 
                @select="option => (searchTerm = searchTerm.replace(/\w+$/, option))" 
                @select="option => (searchTerm = option)"
                -->
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
        </div>
        <div class="column has-text-centered">
          <b-button
            type="is-primary"
            class="is-war"
            native-type="submit"
            v-on:click="runQuery(searchTerm)"
            icon-left="search"
          >
            search
          </b-button>
        </div>
        <!--
          <b-button native-type="submit" @click.native.stop.prevent="runQuery">
          Submit
        </b-button>
      </form>
      --></div>
    </div>
  </section>
</template>

<script>
import TeaScoutApi from "@/api/TeaScoutApi.js";
import CategorySelect from "@/components/CategorySelect.vue";

export default {
  name: "SearchBar",
  components: {
    CategorySelect
  },
  data() {
    return {
      data: [],
      isFetching: false
    };
  },
  methods: {
    getSuggestions() {
      if (this.searchTerm != "") {
        this.isFetching = true;
        TeaScoutApi.autocomplete(
          this.$store.state.selectedCategory.title,
          this.searchTerm
        )
          .then(data => {
            this.data = [];
            this.data = data.result;
          })
          .catch(err => {
            this.data = [];
            throw err;
          })
          .finally(() => {
            this.isFetching = false;
          });
      }
    },
    runQuery() {
      if (this.searchTerm != "") {
        const loadingComponent = this.$buefy.loading.open();
        TeaScoutApi.search(
          this.$store.state.selectedCategory.title,
          this.searchTerm
        ).then(data => {
          this.$store.dispatch("newSearch", { data: data.result });
          loadingComponent.close();
        });
      }
    }
  },
  computed: {
    searchTerm: {
      get() {
        return this.$store.getters.searchTerm;
      },
      set(value) {
        this.$store.commit("setSearchTerm", value);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input ::placeholder {
  color: #000 !important;
}
</style>
