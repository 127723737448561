<template>
  <div class="search">
    <SearchBar />
    <div class="tile is-ancestor">
      <div class="tile is-2 is-parent">
        <div class="tile is-child">
          <Filters />
        </div>
      </div>
      <div class="tile is-parent">
        <div class="tile is-child">
          <TeaTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import SearchBar from "@/components/SearchBar.vue";
import TeaTable from "@/components/TeaTable.vue";
//import TagViewer from "@/components/TagViewer.vue";
import Filters from "@/components/Filters.vue";
// import TeaScoutApi from "@/api/TeaScoutApi.js";

export default {
  name: "Search",
  components: { SearchBar, TeaTable, Filters },
  mounted() {
    this.$nextTick(function() {});
  }
};
</script>
